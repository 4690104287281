import * as React from "react"

import logo from '../assets/ebisurecordlogo.png'

import Layout from "../components/layout"

const NotFoundPage = () => (
    <Layout className={"not-found"}>
        <div className={"text-center text-white "}>
            <img src={logo} alt={"logo ebisu"}/>
            <h1>404 : Not found</h1>
            <p>You just hit a route that doesn&#39;t exist.</p>
        </div>
    </Layout>
)

export default NotFoundPage
